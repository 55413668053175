<template>
  <v-row justify="center">
    <v-col cols="12" class="align-center">
      <v-row dense>
        <v-col cols="12">
          <v-form @submit.prevent="search" >
            <!-- :items="possibleIngredients" -->
            <v-combobox persistent-hint hint="n: or name: looks by name, i: or ing: looks by ingredient"
              :filter="filter"
              :hide-details="!itemsToSearch"
              :items="searchItemOptions"
              multiple small-chips
              filled
              class="rounded-lg"
              :search-input.sync="itemsToSearch"
              :loading="searchIsLoading"
              v-model="ingredientsSearch"
              label="Ingredients or Recipe Name"
              @click:clear="clearSearch"
              append-outer-icon="mdi-tune"
              @click:append-outer="filterDialog = !filterDialog"
            >
              <template v-slot:item="data">
                <template v-if="(typeof data.item !== 'object')">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else-if="data.item.ingredientType == 'inventory'">
                  <v-list-item-avatar>
                    <v-avatar color="grey lighten-4">
                      <span class="headline">{{data.item.icon}}</span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="data.item.text"></v-list-item-title>
                    <v-list-item-subtitle v-text="data.item.value"></v-list-item-subtitle>
                  </v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <v-avatar color="grey lighten-4">
                      <span class="headline">{{data.item.icon != null ? data.item.icon : '🧊'}}</span>
                    </v-avatar>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title v-text="data.item.text"></v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
              <template v-slot:selection="data">
                <template v-if="typeof data.item === 'object'">
                  <v-chip>
                    <v-avatar color="grey lighten-4" left>
                      <span>{{data.item.icon}}</span>
                    </v-avatar>
                    {{data.item.text}}
                  </v-chip>
                </template>
                <template v-else>
                  <v-chip>
                    <v-avatar color="grey lighten-4" left>
                      <span>🧊</span>
                    </v-avatar>
                    {{data.item}}
                  </v-chip>
                </template>
              </template>
            </v-combobox>
          </v-form>
        </v-col>
        <v-col cols="12" class="text-right" align-self="center">
          <v-btn rounded color="success" @click="search">Find <v-icon right>mdi-text-box-search</v-icon></v-btn>
        </v-col>
      </v-row>
    </v-col>

    <v-col v-for="recipe in this.foundSearchItems" :key="recipe.id"
      cols="12" md="4"
    >
      <!-- Recipe Cards -->
      <recipe-card :recipe="recipe" :user="user" @addRecipe="addToRecipes"></recipe-card>
    </v-col>

    <v-bottom-sheet width="100%" v-model="filterDialog">
      <v-sheet>
          <div>
            <v-subheader>Recipe Category</v-subheader>
          </div>
          <div>
            <v-chip-group multiple show-arrows v-model="filters"
              active-class="deep-purple--text text--accent-4"
            >
              <v-chip filter outlined value="popular">Popular Recipes</v-chip>
              <v-chip v-if="user.loggedIn" filter outlined value="awesomeDrinks">AwesomeDrinks</v-chip>
              <v-chip v-if="user.loggedIn" filter outlined value="cocktailDB">CocktailDB</v-chip>
              <v-chip filter outlined value="everything">Everything</v-chip>
            </v-chip-group>
          </div>

          <div class="py-3 text-center">
            <v-btn class="info rounded-lg" @click="filterDialog = false">Show Results</v-btn>
            <v-btn text @click="clearFilters">Clear</v-btn>
          </div>
      </v-sheet>
    </v-bottom-sheet>

  </v-row>
</template>
<script>
  import cocktaildb from '../api/cocktaildb'
  import awesomeDrinks from '../api/awesomeDrinks'

  import RecipeCard from '../components/RecipeCard.vue'

  import { mapGetters, mapState } from 'vuex'

  export default {
  components: { RecipeCard },
    name: 'FindRecipesByIngredients',
    data: () => ({
      recipes: [],
      popularRecipes: [],
      filters: ['popular','public', 'mine', 'everything'],
      types: ['Whisky', 'Brandy', 'Vodka', 'Rum', 'Gin', 'Tequila', 'Liqueur', 'Other', 'All'],

      ingredientsSearch :[],
      ingredientFilter: ['all'],

      filterDialog: false,

      qrcodeDialog: false,


      itemsToSearch: null,
      foundSearchItems: [],
      searchIsLoading: false,
    }),
    mounted(){
      if(!!localStorage.getItem('filtersPreferences')){
        let filtersPrefs = JSON.parse(localStorage.getItem('filtersPreferences'));
        this.filters = filtersPrefs.filters;
        this.ingredientFilter = filtersPrefs.ingredientFilter;
      }
    },
    watch: {
      //Keep track of filters, to save
      filters(newFilters){

        let preferencesStorage = {
          filters: newFilters,
          ingredientFilter: this.ingredientFilter
        };

        localStorage.setItem('filtersPreferences', JSON.stringify(preferencesStorage));
      }
    },
    computed: {
      // This is for data related to the firebase database model
      ...mapState({
        storeRecipes: 'recipes',
        storePopularRecipes: 'popularRecipes',
        user: 'user',
      }),

      ...mapGetters({
          possibleIngredients: 'getPossibleIngredients',
          getRecipesByIngredients: 'getInventoryByIngredient'
      }),

      searchItemOptions(){
        var returnValue = [];
        if (this.itemsToSearch === null || this.itemToSearch === '') return returnValue;

        returnValue.push({ divider: true });
        returnValue.push({ header: 'Recipe Name' });
        returnValue.push({
          text: this.itemsToSearch,
          value: `r:${this.itemsToSearch}`,
          icon: '📖',
          type: 'recipe'
        });

        returnValue.push({ divider: true });
        returnValue.push({ header: 'Ingredient' });
        returnValue.push({
          text: this.itemsToSearch,
          value: `i:${this.itemsToSearch}`,
          icon: '🧊',
          type: 'ingredient'
        });

        return returnValue;
      }
    },
    methods: {
      filter(item, queryText, itemText){
        if (item.header) return true;
        if (item.divider) return true;

        if(queryText == null) return false;

        return (
          item.text.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
          item.value.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1
        );
      },
      search(){
        if(this.ingredientsSearch == [] || this.ingredientsSearch.length == 0){
          return;
        }

        //If it's short
        if(this.ingredientsSearch <= 0) {
          this.$emit('showAlert', {text: 'You didn\'t enter enough ingredients.', type: 'warning'});
          return;
        }

        //If it's loading
        if(this.searchIsLoading) return;

        this.searchIsLoading = true;
        this.fetchDebounced();
      },
      clearSearch(){
        this.foundSearchItems = []
      },
      addToRecipes(recipe){
        console.log(recipe)
        var recipeToAdd = recipe;

        if(recipe.hasOwnProperty('id')){
          delete recipeToAdd.id;
        }

        if(recipe.hasOwnProperty('type')){
          delete recipeToAdd.type;
        }

        for(const [key, value] of Object.entries(recipeToAdd)){
          if(value === undefined || value === null){
            delete recipeToAdd[key];
          }
        }

        recipeToAdd.ownerId = this.user.data.id;

        // Check to see if the recipe already exists in the store
        var itemFoundInStore = this.storeRecipes.findIndex( item => item.name == recipeToAdd.name && item.ingredients.length == recipeToAdd.ingredients.length && item.steps.length == recipeToAdd.steps.length)

        if(itemFoundInStore >= 0){
          this.$emit('showAlert', {text: 'Hmm, you already have the same or similar recipe 😢', type: 'error'})
          return;
        }

        // Otherwise the item doesn't exist in the store, so add it to the store and remove the item from the search results.

        // Find the index of the recipe in foundSearchItems
        var recipeToRemove = this.foundSearchItems.findIndex(item => {
          return item.name == recipeToAdd.name && item.ingredients.length == recipeToAdd.ingredients.length && item.steps.length == recipeToAdd.steps.length;
        })

        // If it exists, remove it (so users can't add it again)
        if(recipeToRemove >= 0){
          this.foundSearchItems.splice(recipeToRemove,1);
        }

        this.$store.dispatch('addRecipe', recipeToAdd);
        this.$emit('showAlert', {text: 'Success! Enjoy your recipe in your stash!', type: 'success'})

      },
      fetchDebounced(){
        clearTimeout(this._searchTimerId)

        this._searchTimeId = setTimeout(() => {
          this.searchForDrink()
        }, 2000)
      },

      searchForDrink(){
        var ingredientsToSearchFor = [];
        var recipesToSearchFor = [];

        // build ingredient search string
        this.ingredientsSearch.forEach(item => {
          if(typeof item === 'string'){
            ingredientsToSearchFor.push(item)
          }
          if(typeof item === 'object' && item.type === 'ingredient'){
            ingredientsToSearchFor.push(item.text.toLocaleLowerCase());
          }
          if(typeof item === 'object' && item.type === 'recipe'){
            recipesToSearchFor.push(item.text.toLocaleLowerCase());
          }
        })

        if(ingredientsToSearchFor.length >= 1){
          var searchString = encodeURI(ingredientsToSearchFor.join(','));

          if(this.filters.includes('everything')){

            //CocktailDB
            cocktaildb.getDrinksByIngredients(searchString, (recipes) => {
              // If failure
              if(!recipes){
                this.$emit('showAlert', {text: '⚠️ something went wrong with CDB 😟.', type: 'error'});
                return;
              }

              recipes.forEach(item => {
                this.foundSearchItems.push(item)
              })
              // this.foundSearchItems = recipes;
            })

            //AwesomeDrinks
            awesomeDrinks.getRecipesByIngredientsAD(searchString, (recipes) => {
              if(!recipes){
                this.$emit('showAlert', {text: '⚠️ Something went wrong with AD 😟.', type: 'error'});
                return;
              }

              recipes.forEach(item => {
                this.foundSearchItems.push(item);
              })
            })

            // Popular Recipes
            this.storePopularRecipes.forEach(popularRecipe => {

              var popularRecipeContainsAMatch = popularRecipe.ingredients.some(item => {

                var ingredientsNames = [];
                ingredientsNames.push(item.type.name);

                // Build up the array of key words to find
                if(item.type.name.indexOf(' ') >= 0){
                  item.type.name.split(' ').forEach(word => {
                    ingredientsNames.push(word)
                  })
                }

                // return if there's any ingredients that match the searh parameters
                return ingredientsNames.some( itm => ingredientsToSearchFor.includes(itm));
              })

              if(popularRecipeContainsAMatch){
                this.foundSearchItems.push(popularRecipe);
              }
            });

          }
        }

        if(recipesToSearchFor.length >= 1){
          var recipeToFind;

          if(recipesToSearchFor.length > 1){
            this.$emit('showAlert', {text: 'Only searching for the first recipe.', type: 'warning'});
            recipeToFind = recipesToSearchFor.shift();
          }

          recipeToFind = recipesToSearchFor[0];

          // search cdb
          cocktaildb.searchForDrink(recipeToFind, (recipes) => {
            if(!recipes){
              this.$emit('showAlert', {text: '⚠️ something went wrong with CDB 😟.', type: 'error'});
              return;
            }

            recipes.forEach(item => {
              this.foundSearchItems.push(item)
            })
          })

          awesomeDrinks.getRecipesByNameAD(recipeToFind, (recipes) => {
            if(!recipes){
              this.$emit('showAlert', {text: '⚠️ Something went wrong with AD 😟.', type: 'error'});
              return;
            }

            recipes.forEach(item => {
              this.foundSearchItems.push(item);
            })
          })

        }

        this.searchIsLoading = false;
      },
      clearFilters(){
        this.ingredientFilter = ['all']
        this.filters = []

        if(this.user.loggedIn){
          this.filters.push('mine')
        }

        this.filters.push('popular')
        // filters: ['popular', 'mine'],

      },
      matchesFilter(recipe){
        // Need to check ingredients to see if it matches filters
      }
    }
  };
</script>