import { App } from '../firebase/app';
require('firebase/functions');

// Cloud Firestore Functions
var cloudFunctions = App.functions();

// If testing, do the following 
if(location.hostname === 'localhost'){
  cloudFunctions.useFunctionsEmulator('http://localhost:5001')
}

function getRecipesByNameAD(searchTerm, cb){
  var searchForRecipesAD = cloudFunctions.httpsCallable('searchRecipesByNameAD')
  searchForRecipesAD ({searchTerm: searchTerm})
    .then( (result) => {
      console.log(result)
      // const { foundRecipes } = result.data
      cb(result.data)
    })
    .catch( err => {
      console.log(err);
    })
}

function getRecipesByIngredientsAD(ingredients, cb){
  var searchForRecipesByIngredientsAD = cloudFunctions.httpsCallable('searchADRecipesByIngredients')
  searchForRecipesByIngredientsAD({ingredients: ingredients})
    .then( (result) => {
      console.log(result)
      cb(result.data)
    })
    .catch( err => {
      console.log(err);
    })
}

export default {
  getRecipesByNameAD, getRecipesByIngredientsAD
}